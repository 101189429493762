$wondr-primary: #12AECC;
$wondr-secondary: #1E1843;
$wondr-dark: #111820;
$wondr-dark-mid: #181F26;
$wondr-dark-comp: #1F252B;
$wondr-gray-bg: #f0f1f7;
$card-border-color: #e7eaec;

@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');


// Custom scss

.sidebar-wrapper {
  .flex-start-buttons .mantine-Button-inner {
    justify-content: flex-start;
  }
}

.cursor-pointer {
  cursor: pointer;
}

// * Articles * //

.ql-tooltip {
  z-index: 105 !important;
  input {
    width: 700px !important;
  }
}


.snap-bottom {
  position: sticky;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  border-radius: 0;
}

.p-rel {
  position: relative;
}

.h-100 {
  height: 100%;
}

.image-wrapper {
  position: relative;
  overflow: hidden;

  .mantine-Dropzone-root {
    background-color: transparent;
  }

  .active-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
    background-size: cover;
  }
}

.video-wrapper {
  .player-wrapper {
    width: 100%;
    position: relative;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    .missing-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }

    &[data-vimeo-initialized="true"] {
      .missing-wrapper {
        display: none;
      }
    }
  }
}

.article-controls {
  .reorder-button {
    color: white;
    transition: all 0.2s ease-in-out;
    
    &:hover {
      transform: scale(1.1);
      background-color: rgba(17, 24, 32, 0.8); // Using $wondr-dark with opacity
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  .mantine-NumberInput-input {
    width: 50px;
    text-align: center;
    font-weight: 600;
    border: none;
    background-color: rgba($wondr-dark, 0.8);
    font-size: 1rem;
    padding: 4px 8px;
    border-radius: 4px;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);

    &:focus {
      border: 1px solid rgba($wondr-primary, 0.5);
      background-color: rgba($wondr-dark, 0.9);
    }

    &:hover {
      background-color: rgba($wondr-dark, 0.9);
    }
  }

  .mantine-NumberInput-wrapper {
    border: none;
  }

  .order-display {
    color: white;
    background-color: rgba(17, 24, 32, 0.8);
    padding: 4px 12px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}

// * End Articles * //
